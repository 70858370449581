@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	thead tr {
		@apply bg-red-500 font-medium text-white;
	}

	thead td {
		@apply py-4;
	}

	td,
	th {
		@apply px-4 py-2 text-left text-black;
	}

	tr:nth-child(even) {
		@apply bg-gray-100;
	}
}

@layer components {
	.page {
		@apply m-10 transition-all relative;
	}
	.page-section {
		@apply overflow-hidden bg-white rounded-xl shadow-lg p-5;
	}
	.heading {
		@apply text-3xl text-red-300 ml-20 p-5 font-semibold;
	}
	.table-icon {
		@apply h-5 w-5 text-white scale-150;
	}
	.iconn {
		@apply h-4 w-4 text-blue-300 scale-150;
	}
	.button-green {
		@apply h-auto w-fit p-2 m-2 rounded-lg bg-green-400 cursor-pointer text-black;
	}
	.button-warn {
		@apply h-auto w-fit p-2 m-2 rounded-lg bg-yellow-400 cursor-pointer text-black;
	}
	.button-contribution {
		@apply h-auto w-fit mx-2 p-1  rounded-lg bg-yellow-400 cursor-pointer text-black;
	}

	.modal-control {
		position: fixed;
		z-index: 10000;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(0, 0, 0, 0.55);
		overflow-x: auto;
		inset: 0;
	}

}
