.auth {
    margin: 3rem auto;
    width: 95%;
    max-width: 25rem;
    border: 3px solid black;
    border-radius: 6px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 3rem;
    text-align: center;
    color: white;
}

.auth h1 {
    text-align: center;
    color: white;
}

.control {
    margin-bottom: 0.5rem;
    margin-top: 1.5rem;
}

.control label {
    display: block;
    color: white;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.control input {
    font: inherit;
    background-color: #f1e1fc;
    color: #38015c;
    border-radius: 4px;
    border: 2px solid black;
    width: 100%;
    text-align: left;
    padding: 0.25rem;
}
.controlCheckbox{
    margin-bottom: 0.5rem;
    margin-top: 1.5rem;
}

.controlCheckbox label {
    margin-left: 0.5rem;    
    color: white;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.controlCheckbox input {
    font: inherit;
    background-color: #f1e1fc;
    color: #38015c;
    border-radius: 4px;
    border: 2px solid black;
    width: 2rem;
    text-align: left;
    padding: 0.25rem;
}


.actions {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.actions button {
    cursor: pointer;
    font: inherit;
    color: white;
    background-color: #000000;
    border: 1px solid black;
    border-radius: 4px;
    padding: 0.5rem 2.5rem;
    margin-top: 1rem;
}

.actions button:hover {
    background-color: #c291e2;
    color: #38015c;
}

.actions .toggle {
    margin-top: 1rem;
    background-color: transparent;
    color: #ffffff;
    border: none;
    padding: 0.15rem 1.5rem;
    font-weight: 400;
}

.actions .toggle:hover {
    background-color: transparent;
    color: #000000;
}
